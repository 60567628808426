import React from "react";
const Nopagefound = () =>{

    return (
        <div>
            <h3>404 NOT FOUND</h3>
        </div>
    );
};

export default Nopagefound;